import { render, staticRenderFns } from "./TeamsTabResult.vue?vue&type=template&id=15b307ea&scoped=true&"
import script from "./TeamsTabResult.vue?vue&type=script&lang=js&"
export * from "./TeamsTabResult.vue?vue&type=script&lang=js&"
import style0 from "./TeamsTabResult.vue?vue&type=style&index=0&id=15b307ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15b307ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCardTitle,VContainer,VSkeletonLoader})
